<template>
    <div class="container">
        <crud ref="crud" :options="options">
            <template slot="header">
                <el-form-item v-if="$perm('admin_role_auth')">
                    <el-button type="success" icon="el-icon-thumb" @click="assignAuth">分配权限</el-button>
                </el-form-item>
            </template>
        </crud>
        <tb-dialog ref="authDialog" title="分配权限" @onConfirm="onAuthConfirm" @onClosed="onAuthClosed">
            <el-tree ref="menus"
                     :data="menus"
                     node-key="id"
                     :default-checked-keys="auth"
                     style="height: 450px; overflow-y: auto"
                     :props="{children: 'children',label: 'name'}"
                     show-checkbox/>
        </tb-dialog>
    </div>
</template>

<script>
export default {
    name: "List",
    data: function () {
        return {
            options: {
                addBtn: true,
                editBtn: true,
                delBtn: true,
                pager: true,
                addUrl: 'admin.role.add',
                addPerm: 'admin_role_add',
                editUrl: 'admin.role.edit',
                editPerm: 'admin_role_edit',
                delUrl: 'admin.role.delete',
                delPerm: 'admin_role_delete',
                listUrl: 'admin.role.page',
                formLabelWidth: '100px',
                columns: [
                    {prop: "name", label: "角色名称", width: 120, required: true},
                    {prop: "sort", label: "角色排序", width: 100, value: 0, type: 'number'},
                    {prop: "remark", label: "角色备注", width: 100},
                ],
            },
            menus: [],
            auth: [],
            role: null
        }
    },
    methods: {
        assignAuth: function () {
            const rows = this.$refs.crud.getSelectedRows()
            if (rows.length <= 0) return this.$message.warning("未选中任何角色~")
            if (rows.length !== 1) return this.$message.warning("一次只能为一个角色授权~")
            this.role = rows[0]
            this.$refs.authDialog.show()
            this.getMenus()
            this.getAuth()
        },
        getMenus: function () {
            this.$helper._get(this, 'admin.menu.lists', {}, resp => {
                this.menus = resp.data
            }, false)
        },
        getAuth: function () {
            this.$helper._get(this, 'admin.role.menu.auth', {role_id: this.role.id}, resp => {
                this.auth = resp.data
            })
        },
        onAuthConfirm: function () {
            const checked = this.$refs.menus.getCheckedNodes(false, true)
            this.$helper._post(this, 'admin.role.menu.submit', {
                role_id: this.role.id,
                menus: checked.map(item => item.id)
            }, resp => {
                this.$message.success(resp.message)
                this.$refs.authDialog.hide()
                this.auth = []
            })
        },
        onAuthClosed: function () {
            this.auth = []
        }
    }
}
</script>

<style scoped>

</style>
